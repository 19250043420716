<template>
<div class="mainform">
    <div class="form">
        <el-form ref="form" class="content" :model="form" label-width="140px" :disabled="false">
          <div class="col col8">
            <el-form-item label="账户名" prop="bankAccountName" :rules="{ required: true, message: '请输入账户名', trigger: 'blur' }">
                <el-input v-model="form.bankAccountName"></el-input>
            </el-form-item>
          </div>
          <div class="col col8">
            <el-form-item label="开户行名称" required prop="openingBankName" :rules="{ required: true, message: '请输入开户行名称', trigger: 'blur' }">
                <el-input v-model="form.openingBankName"></el-input>
            </el-form-item>
          </div>
          <div class="col col8">
            <el-form-item label="银行账号" required prop="bankAccount" :rules="{ required: true, message: '请输入银行账号', trigger: 'blur' }">
                <el-input v-model="form.bankAccount"></el-input>
            </el-form-item>
          </div>
          <div class="col col8">
            <el-form-item label="开户行所在省市" prop="city" :rules="{ required: true, message: '请选择省市', trigger: 'blur' }">
                <area-selector
                  :option = "areaOption"
                  :required="false"
                  :provincedData = "form.province"
                  :citydData = "form.city"
                  @changAction="changAction"></area-selector>
            </el-form-item>
          </div>
          <div class="col col8">
            <el-form-item label="币种" required prop="currency" :rules="{ required: true, message: '请选择币种', trigger: 'blur' }">
                <el-select filterable v-model="form.currency" placeholder="请选择">
                  <el-option label="RMB" value='RMB'></el-option>
                  <el-option label="USD" value='USD'></el-option>
                </el-select>
            </el-form-item>
          </div>
          <div class="col col8">
            <el-form-item label="备注">
                <el-input v-model="form.bz"></el-input>
            </el-form-item>
          </div>
        </el-form>
    </div>
</div>
</template>

<script>
import Component from '@/assets/js/components.js'
export default {
  name: 'AddShroffAccountNumber',
  components: Component.components,
  props: ['formData'],
  data: function () {
    return {
      form: this.formData,
      areaOption: {
        country: false,
        district: false
      }
    }
  },
  methods: {
    changAction: function (data) {
      if (data.province) {
        this.form.province = data.province
      }
      if (data.city) {
        this.form.city = data.city
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/assets/css/elForm.scss';
  .mainform{
    background: none;
    padding: 0;
    .form{
      background: none;
      .content{
        padding: 0px 0;
      }
    }
  }
.mainform .form .el-form .el-form-item:last-child {
    margin-bottom: 0px;
}
.areaSelect{
  margin-bottom: 5px;
    :deep(.el-select){
        width: 49% !important;
    }
}
</style>
